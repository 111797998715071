import React from "react";
import "../css/Modal.css";

let listen = {};

const _Init = () => {
    let [modals, setModals] = React.useState([]);
    listen.set = (get) => {
        setModals([...modals, get]);
    };

    listen.clear = () => {
        setModals([]);
    };

    return <>{modals.map((i) => i)}</>;
};

const _Add = (e) => {
    listen.set(e);
};

const _ClosedAll = () => {
    listen.clear();
};

const _ClosedID = (id) => {
    listen[id]();
};

const _Shake = (e) => {
    if (e.target.dataset.overflow === "true") {
        let a = e.target.querySelector(".b");
        a.setAttribute("shake", true);
        setTimeout(() => {
            a.setAttribute("shake", false);
        }, 1000);
    }
};

const _Main = ({ children, title, menu, uuid, full = false }) => {
    let [active, setActive] = React.useState(true);

    listen[uuid] = (id) => {
        setActive(false);
    };

    return (
        <>
            {active ? (
                <div
                    className="modal o"
                    data-overflow="true"
                    onClick={Modal.Shake.bind(this)}
                    key={uuid}
                >
                    <div
                        className="b"
                        style={{ height: full ? "90%" : "auto" }}
                    >
                        <div className="h">
                            {" "}
                            <span className="ui-link">{title}</span>{" "}
                            <span className="modal-menu">{menu}</span>{" "}
                            <span className="modal-empty"></span>{" "}
                        </div>
                        <div
                            className="x icon icon-io-close-filled"
                            onClick={() => {
                                setActive(false);
                            }}
                        ></div>
                        {children}
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

const Modal = {
    Add: _Add,
    Init: _Init,
    Main: _Main,
    Shake: _Shake,
    ClosedID: _ClosedID,
    ClosedAll: _ClosedAll,
};

export default Modal;
