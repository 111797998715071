import React from "react";
import "../css/Alerts.css";

let listener = {};

function _Init() {
    let [list, setList] = React.useState([]);
    listener.set = (get) => setList([...list, get]);
    listener.del = (key) => setList([]);

    return <>{list.map((i) => i.item)}</>;
}

function _Send(e, callback) {
    let key = new Date().getTime();
    e.key = key;
    listener.set({
        item: <AlertsItem key={key} data={e} callback={callback} />,
        key: key,
    });
}

function AlertsItem({ data, callback }) {
    data.button = data.button === undefined ? "Подтвердить" : data.button;

    return (
        <div className="alerts">
            <div className="--item">
                <div className="--form">
                    <div className="--name">{data.title}</div>
                    <div className="--text">{data.text}</div>
                </div>
                <div
                    className="--send"
                    onClick={() => {
                        callback();
                        listener.del();
                    }}
                >
                    {data.button}
                </div>
            </div>
            <div className="--body" onClick={listener.del}></div>
        </div>
    );
}

let Alerts = {
    Send: _Send,
    Init: _Init,
};

export default Alerts;
